import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import clsx from "clsx";
import { Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/pro-duotone-svg-icons";

import SearchService from "../../../../../services/SearchService";
import apiClient from "../../../../../auth/apiClient";
import { useStyles as useSharedStyles } from "./Results.style"
import { useStyles } from "./AccessHolderResultDetail.style";
import useCurrentFacilityTimezone from "../../../../../hooks/useCurrentFacilityTimezone";
import AccessHolderStatusChangeButton from "../../../Modals/AccessHolderStatusChangeButton";
import AccessHolderModeChangeButton from "../../../Modals/AccessHolderModeChangeButton";
import _ from "lodash";
import {useCoreEntityContext} from "../../../../../hooks/useCoreEntitySlice";

const searchService = new SearchService(apiClient);

export const AccessHolderResultDetail = ({ accessHolderId, onClose }) => {

    const sharedClasses = useSharedStyles();
    const classes = useStyles();

    const [accessHolderDetails, setAccessHolderDetails] = useState(null);
    const [accessHolderName, setAccessHolderName] = useState("");
    const [recentActivity, setRecentActivity] = useState(null);
    const [times, setTimes] = useState({ start: null, end: null });
    const [isLoading, setIsLoading] = useState(true);
    const useCoreEntitySlice = useCoreEntityContext();

    const { convertUtcDate } = useCurrentFacilityTimezone();

    const scopeAwareContextId = useSelector(
        (state) => state.entityScope?.facilityGroupId ?? (useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID)
    );

    const fetchAccessHolder = useCallback(async () => {
        if (!accessHolderId) {
            return;
        }
        try {
            setIsLoading(true);
            const response = await searchService.getAccessHolder(accessHolderId, scopeAwareContextId);
            setAccessHolderDetails(response.data);
            setAccessHolderName(response.data?.firstName?.concat(" ", response.data?.lastName));
        } catch (error) {
            console.log('Failed to load access holder: ', error);
        } finally {
            setIsLoading(false);
        }
    }, [accessHolderId, scopeAwareContextId]);

    useEffect(() => {
        if (accessHolderDetails?.recentActivity && accessHolderDetails?.recentActivity?.length > 0) {
            setRecentActivity(accessHolderDetails.recentActivity[0]);
        }
        setTimes({
            start: formatDt(accessHolderDetails?.contractStartDate),
            end: formatDt(accessHolderDetails?.contractEndDate),
        });
    }, [accessHolderDetails]);

    const formatDt = (dt) => {
        if (!dt) {
            return null;
        }
        // TODO: I don't like this, use facility timezone?
        // maintaining existing functionality for now
        return moment.utc(dt).local().format("llll");
    };

    const nullFilter = (d) => {
        return d === "Invalid date" ? "" : d;
    };

    useEffect(() => {
        if (!_.isNil(accessHolderId) && _.isNil(accessHolderDetails)) {
            fetchAccessHolder();
        }
    }, [accessHolderId])

    const TimesGrid = () => {
        return (
            <Grid container spacing={1}>
                {times.start && (
                    <Grid item xs={12} md={times?.end ? 6 : 12}>
                        <Typography color="textSecondary" className={classes.centered}>
                            Start Date
                        </Typography>
                        <Typography className={clsx(classes.centered, "start-date")} data-value={times.start}>
                            {times.start}
                        </Typography>
                    </Grid>
                )}
                {times.end && (
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary" className={classes.centered}>
                            End Date
                        </Typography>
                        <Typography className={clsx(classes.centered, "end-date")} data-value={times.end}>
                            {times.end}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    };

    const RecentActivity = () => {
        return (
            <div>
                <Typography
                    color="textSecondary"
                    className={clsx(classes.centered, classes.smallTopMargin)}>
                    Recent Activity
                </Typography>
                <Paper elevation={3} className={clsx(classes.smallTopMargin, classes.recentActivityGrid)}>
                    <Table size="medium">
                        <TableBody>
                            <TableRow>
                                <TableCell>Device Name</TableCell>
                                <TableCell align="right" data-value={recentActivity.deviceName}>
                                    {recentActivity.deviceName}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="right" data-value={recentActivity.description}>
                                    {recentActivity.description}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Activity Date</TableCell>
                                <TableCell align="right">
                                    <Moment
                                        filter={nullFilter}
                                        format="dddd, MMM D, YYYY hh:mm:ss A"
                                        data-value={convertUtcDate(recentActivity?.activityDate)}
                                        data-testid="activity-date">
                                        {convertUtcDate(recentActivity?.activityDate)}
                                    </Moment>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reason</TableCell>
                                <TableCell align="right" data-value={recentActivity.reason}>
                                    {recentActivity.reason}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    };

    return isLoading
        ? <LinearProgress />
        : <div>
            {accessHolderDetails
                ? <Grid container spacing={1} className="access-holder-modal-content">
                    <Grid item xs={12}>
                        <Typography color="textSecondary"
                            variant="h6"
                            className={clsx(classes.centered, classes.bottomSpace, "access-holder-name")}
                            data-value={accessHolderName}>
                            {accessHolderName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary"
                            className={clsx(classes.centered, classes.smallTopMargin)}>
                            Status
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AccessHolderStatusChangeButton
                            accessHolderDetails={accessHolderDetails}
                            onChangeStatusChange={(details) => setAccessHolderDetails(details)}
                        ></AccessHolderStatusChangeButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary"
                            className={clsx(classes.centered, classes.smallTopMargin)}>
                            Mode
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AccessHolderModeChangeButton
                            accessHolderDetails={accessHolderDetails}
                            onChangeStatusChange={(mode) => setAccessHolderDetails({ ...accessHolderDetails, mode: mode })}
                        ></AccessHolderModeChangeButton>
                    </Grid>
                    {(times.start || times.end) && (
                        <Grid item xs={12} className={classes.topSpace}>
                            <TimesGrid />
                        </Grid>
                    )}
                    {recentActivity && (
                        <Grid item xs={12} className={clsx(classes.centered, classes.topSpace)}>
                            <RecentActivity></RecentActivity>
                        </Grid>
                    )}
                </Grid>
                : <p>Failed to load AccessHolder</p>
            }
            <div className={clsx(classes.footerDiv, classes.topSpace)}>
                <Button
                    startIcon={<FontAwesomeIcon icon={faCaretUp} />}
                    className={clsx(sharedClasses.collapseResult, "collapse")}
                    onClick={onClose}>
                    Collapse
                </Button>
            </div>
        </div>
};